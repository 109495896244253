import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Aggregator from './components/Aggregator'

function App() {
  return (
    <div className="App">
      <Navbar bg="dark" expand="sm" variant="dark">
        <Container>
          <Navbar.Brand href="/">Aggregator</Navbar.Brand>
        </Container>
      </Navbar>
      <Aggregator />
    </div>
  );
}

export default App;
