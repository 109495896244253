import { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import { Container, Row, Col } from 'react-bootstrap'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OrderBook from './OrderBook'
import TradeHistory from './TradeHistory'
import AggregatorService from '../services/AggregatorService.js';

let Aggregator = () => {
    const [exchange, setExchange] = useState({
        exchange : 'binance'
    });

    const [symbol, setSymbol] = useState({
        symbol : 'btcusdt'
    });

    const [minOrder, setMinOrder] = useState({
        minOrder : ''
    });

    const [minTrade, setMinTrade] = useState({
        minTrade : ''
    });

    const [applyDisabled, setApplyDisabled] = useState({
        applyDisabled : true
    });

    const handleExchangeChange = (exchange) => {
        setExchange({exchange : exchange});
        AggregatorService.setExchange(exchange);
        AggregatorService.log();
    }

    const handleSymbolChange = (symbol) => {
        setSymbol({symbol : symbol});
        AggregatorService.setSymbol(symbol);
        AggregatorService.log();
    }

    const handleMinOrderChange = (event) => {
        setMinOrder({minOrder : event.target.value});
        handleApplyDisabled(event.target.value, minTrade.minTrade);
    }

    const handleMinTradeChange = (event) => {
        setMinTrade({minTrade : event.target.value});
        handleApplyDisabled(minOrder.minOrder, event.target.value);
    }

    const handleApplyDisabled = (minOrder, minTrade) => {
        if (minOrder === AggregatorService.getMinOrder() &&
            minTrade === AggregatorService.getMinTrade())
            setApplyDisabled({applyDisabled : true});
        else
            setApplyDisabled({applyDisabled : false});
    }

    const handleApplyClick = () => {
        AggregatorService.setMinOrder(minOrder.minOrder);
        AggregatorService.setMinTrade(minTrade.minTrade);
        AggregatorService.log();
        handleApplyDisabled(minOrder.minOrder, minTrade.minTrade);
    }

    useEffect(() => {
        AggregatorService.log();
        console.log(exchange);
        console.log(symbol);
        console.log(minOrder);
        console.log(minTrade);
        console.log(applyDisabled);
    });

    return (
      <>
        <Navbar bg="light" expand="sm" variant="dark">
          <Container>
            <ButtonToolbar>
              <ToggleButtonGroup type="radio" name="exchange" defaultValue={'binance'} onChange={handleExchangeChange} className="me-3">
                <ToggleButton
                  id="tbn-binance"
                  value={'binance'}
                  disabled
                  variant="outline-primary"
                  style={{width: 120}}>Binance</ToggleButton>
                <ToggleButton
                  id="tbn-bybit"
                  value={'bybit'}
                  disabled
                  variant="outline-primary"
                  style={{width: 120}}>Bybit</ToggleButton>
                <ToggleButton
                  id="tbn-bitget"
                  value={'bitget'}
                  disabled
                  variant="outline-primary"
                  style={{width: 120}}>Bitget</ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup type="radio" name="symbol" defaultValue={'btcusdt'} onChange={handleSymbolChange} className="me-3">
                <ToggleButton
                  id="tbn-btcusdt"
                  value={'btcusdt'}
                  disabled
                  variant="outline-primary"
                  style={{width: 120}}>BTCUSDT</ToggleButton>
                <ToggleButton
                  id="tbn-ethusdt"
                  value={'ethusdt'}
                  disabled
                  variant="outline-primary"
                  style={{width: 120}}>ETHUSDT</ToggleButton>
                <ToggleButton
                  id="tbn-bnbusdt"
                  value={'bnbusdt'}
                  disabled
                  variant="outline-primary"
                  style={{width: 120}}>BNBUSDT</ToggleButton>
              </ToggleButtonGroup>
              <InputGroup>
                <Form.Control
                  type="number"
                  onKeyDown={e => ["e","E","+","-",","].includes(e.key) && e.preventDefault()}
                  onChange={handleMinOrderChange}
                  placeholder="Min order"
                  style={{width: 120}}
                />
                <Form.Control
                  type="number"
                  onKeyDown={e => ["e","E","+","-",","].includes(e.key) && e.preventDefault()}
                  onChange={handleMinTradeChange}
                  placeholder="Min trade"
                  style={{width: 120}}
                />
                <Button
                  as="input"
                  type="submit"
                  value="Apply"
                  disabled={applyDisabled.applyDisabled}
                  onClick={handleApplyClick}
                  style={{width: 120}} />
              </InputGroup>
            </ButtonToolbar>
          </Container>
        </Navbar>
        <Container className="mt-3">
          <Row>
            <Col>
              <OrderBook />
            </Col>
            <Col>
              <TradeHistory />
            </Col>
          </Row>
        </Container>
      </>
    )
};

export default Aggregator;
