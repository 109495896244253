import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import AggregatorService from '../services/AggregatorService.js';

let TradeHistory = () => {
    const [state, setState] = useState({
        trades : []
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            AggregatorService.getTrades().then((response) => {
                setState({
                    ...state,
                    trades : response.data
                })
            }).catch((error) => {
                console.log(error);
            });
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
    })

    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Time</th>
              <th>Price</th>
              <th>Amount</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
              {
                  typeof state.trades !== 'undefined' &&
                  typeof state.trades.trades !== 'undefined' &&
                  state.trades.trades.map((trade, index) => {
                      const typeClassName = trade.type === 'buy' ? 'text-success' : 'text-danger';
                      return (
                          <tr key={index}>
                              <td>{new Date(trade.time).toLocaleString('sv')}</td>
                              <td>{trade.price}</td>
                              <td>{trade.quantity}</td>
                              <td className={typeClassName}>{trade.type.toUpperCase()}</td>
                          </tr>
                      )
                  })
              }
          </tbody>
        </Table>
      </>
    )
};

export default TradeHistory;
