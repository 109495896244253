import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import AggregatorService from '../services/AggregatorService.js';

let OrderBook = () => {
    const [state, setState] = useState({
        orders : []
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            AggregatorService.getOrders().then((response) => {
                setState({
                    ...state,
                    orders : response.data
                })
            }).catch((error) => {
                console.log(error);
            });
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
    })

    return (
      <>
        <Container>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>BID Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        typeof state.orders !== 'undefined' &&
                        typeof state.orders.bids !== 'undefined' &&
                        state.orders.bids.map((bid, index) => {
                            return (
                                <tr key={index}>
                                  <td
                                    className='text-success'
                                    title={new Date(state.orders.time).toLocaleString('sv')}>{bid.price}</td>
                                  <td>{bid.quantity}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ASK Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        typeof state.orders !== 'undefined' &&
                        typeof state.orders.asks !== 'undefined' &&
                        state.orders.asks.map((ask, index) => {
                            return (
                                <tr key={index}>
                                  <td
                                    className='text-danger'
                                    title={new Date(state.orders.time).toLocaleString('sv')}>{ask.price}</td>
                                  <td>{ask.quantity}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    )
};

export default OrderBook;
