import axios from 'axios';

class AggregatorService {
    static aggregatorBase = 'http://localhost:8080';
    //static aggregatorBase = 'http://151.248.116.4:8080';
    //static aggregatorBase = 'https://api.bitdata.fund:8444';
    static aggregatorPath = '/api/v1/aggregator/exchange';

    static exchange = 'binance';
    static symbol = 'btcusdt';
    static minOrder = '';
    static minTrade = '';

    static getExchange() {
        return this.exchange;
    }

    static setExchange(exchange) {
        this.exchange = exchange;
    }

    static getSymbol() {
        return this.symbol;
    }

    static setSymbol(symbol) {
        this.symbol = symbol;
    }

    static getMinOrder() {
        return this.minOrder;
    }

    static setMinOrder(minOrder) {
        this.minOrder = minOrder;
    }

    static getMinTrade() {
        return this.minTrade;
    }

    static setMinTrade(minTrade) {
        this.minTrade = minTrade;
    }

    static log() {
        console.log('AggregatorService.exchange='+this.exchange);
        console.log('AggregatorService.symbol='+this.symbol);
        console.log('AggregatorService.minOrder='+this.minOrder);
        console.log('AggregatorService.minTrade='+this.minTrade);
    }

    static getOrders() {
        this.log();
        //const minOrder = this.minOrder === '' ? '' : `&minOrder=${this.minOrder}`
        //const aggregatorMethod = `/orders?exchange=${this.exchange}&symbol=${this.symbol}`
        //const aggregatorUrl = this.aggregatorBase + this.aggregatorPath + aggregatorMethod + minOrder;
        //return axios.get(aggregatorUrl);
        return axios.get('/orders.json');
    }

    static getTrades() {
        this.log();
        //const minTrade = this.minTrade === '' ? '' : `&minTrade=${this.minTrade}`
        //const aggregatorMethod = `/trades?exchange=${this.exchange}&symbol=${this.symbol}`
        //const aggregatorUrl = this.aggregatorBase + this.aggregatorPath + aggregatorMethod + minTrade;
        //return axios.get(aggregatorUrl);
        return axios.get('/trades.json');
    }
};

export default AggregatorService;
